























import Vue from 'vue';
import UserUtils, { User } from '@/utils/UserUtils';
import { DataTableHeader } from 'vuetify';

export default Vue.extend({
  computed: {
    headers(): DataTableHeader<User>[] {
      return [
        {
          text: this.$i18n.tc('user.username'),
          value: 'username',
        },
        {
          text: this.$i18n.tc('user.first_name'),
          value: 'first_name',
        },
        {
          text: this.$i18n.tc('user.last_name'),
          value: 'last_name',
        },
        {
          text: this.$i18n.tc('user.privilege'),
          value: 'privilege',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },

  data: (): {
    items?: User[];
  } => ({
    items: undefined,
  }),

  async mounted() {
    try {
      this.items = await UserUtils.api.list();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    async handleEdit(item: User) {
      await this.$router.push({
        name: 'user-edit',
        params: { id: item.id.toString() },
      });
    },

    async handleCreate() {
      await this.$router.push({
        name: 'user-create',
      });
    },
  },
});
